.card-wrap{
    height: 360px;
    word-wrap: break-word;
    border-radius: 15px;
    padding-inline: 20px;
    padding-block: 20px;
    margin: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-detail-wrap{
    background: white;
    padding-inline: 15px;
    padding-block: 5px;
    position: absolute;
    margin: 15px;
    bottom: 0;
    border-radius: 15px;
    text-align: center;
}

.card-detail-wrap .card-img{
    max-width: 25%;
    position: relative;
    border-radius: 50%;
    border: white solid 5px;
    top: -25px;
    transform: scaleX(-1);
}

.card-detail{
    position: relative;
    color: black;
    top: -15px;
    display: flex;
    flex-direction: column;
}