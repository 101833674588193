@media only screen and (max-width: 1649px) {
    .footer-logo {
        width: 180px!important;
    }

    .contact-info{
        font-size: 13pt!important;
        line-height: 25px!important;
    }

    .contact-us-sub {
        text-indent: 8px!important;
        font-size: 18px!important;
        line-height: 30px!important;
    }

    .socials{
        margin-top: 15px!important;
        column-gap: 5px!important;
        font-size: 25pt!important;
    }
}

@media only screen and (max-width: 1195px) {
    .footer-logo {
        width: 140px!important;
    }

    .contact-info{
        font-size: 12pt!important;
        line-height: 23px!important;
    }

    .contact-us-sub {
        text-indent: 8px!important;
        font-size: 15px!important;
        line-height: 25px!important;
    }
}

@media only screen and (max-width: 885px) {
    .footer-logo {
        width: 130px!important;
    }

    .group-footer{
        flex-direction: column!important;
        row-gap: 25px!important;
        margin-inline: 30px!important;
    }

    .contact-info{
        font-size: 19pt!important;
        line-height: 35px!important;
    }

    .contact-us-sub {
        font-size: 22px!important;
        line-height: 35px!important;
    }

    .socials{
        margin-top: 15px!important;
        column-gap: 4px!important;
        font-size: 30pt!important;
    }
}

@media only screen and (max-width: 600px) {
    .footer-logo {
        width: 120px!important;
    }

    .group-footer{
        margin-inline: 15px!important;
    }

    .contact-info{
        font-size: 17pt!important;
        line-height: 32px!important;
    }

    .contact-us-sub {
        font-size: 20px!important;
        line-height: 35px!important;
    }

    .socials{
        margin-top: 15px!important;
        column-gap: 3px!important;
        font-size: 25pt!important;
    }
}

@media only screen and (max-width: 490px) {
    .contact-info{
        font-size: 14pt!important;
        line-height: 30px!important;
    }

    .contact-us-sub {
        font-size: 17px!important;
        line-height: 28px!important;
    }

    .socials{
        margin-top: 15px!important;
        column-gap: 0!important;
        font-size: 25pt!important;
    }
}


@media only screen and (max-width: 393px) {
    .footer-logo {
        width: 100px!important;
    }

    .contact-info{
        font-size: 11pt!important;
        line-height: 25px!important;
    }

    .contact-us-sub {
        font-size: 14px!important;
        line-height: 25px!important;
    }

    .socials{
        margin-top: 13px!important;
        column-gap: 0!important;
        font-size: 20pt!important;
    }

}

@media only screen and (max-width: 360px) {
    .contact-info{
        font-size: 12pt!important;
        line-height: 25px!important;
    }

    .contact-us-sub {
        font-size: 15px!important;
        line-height: 25px!important;
    }
}

@media only screen and (max-width: 320px) {
    .contact-info{
        font-size: 10pt!important;
        line-height: 22px!important;
    }

    .contact-us-sub {
        font-size: 13px!important;
        line-height: 22px!important;
    }
}



