
@media only screen and (max-width: 1649px) {
    .section-1-wrap-extra{
        max-width: 55% !important;
        margin-top: 50px!important;
    }

    .section-2-wrap-x-{
        margin: 0 60px 5px 60px!important;
    }

    section.two-addition{
        max-width: 100% !important;
        padding: 0!important;
        margin-top: 50px!important;
        row-gap: 50px!important;
    }

    #first-left, #first-right-extra, #first-right {
        width: 50px!important;
        height: auto!important;
    }

    .section-1-wrap, .section-1-wrap-any{
        max-width: 55% !important;
    }

    section.one{
        padding: 50px 50px 0 50px!important;
    }

    section.two{
        padding: 10px 50px 0 50px!important;
        margin-top: 150px!important;
    }


    section.two-extra{
        padding: 0 40px 0 40px!important;
        margin-top: 30px!important;
        justify-content: center!important;
        text-align: center!important;
    }


    .first-col{
        margin-top: 30px!important;
    }

    .four-extra{
        margin-top: 20px!important;
    }

    section.five-extra{
        margin: 0 auto 5px auto!important;

    }

    .section-2-wrap{
        margin-inline: 50px!important;
    }

    .section-2-wrap-x{
        margin: 0 50px 40px 50px!important;
        max-width: 100% !important;
    }

    .section-3-wrap {
        max-width: 100% !important;
        position:unset!important;
        margin-right: 30px!important;
        margin-left: 0!important;
    }

    .three {
        margin-top: 10px !important;
        display: flex !important;
        flex-direction: row !important;
        justify-content: center!important;
        align-items: center!important;
    }

    section.four {
        margin-top: 5px !important;
        margin-bottom: 0 !important;
        flex-direction: row-reverse!important;
        justify-content: center!important;
        align-items: center!important;
    }

    #second-right, #second-left {
        position: unset!important;
    }

}

@media only screen and (max-width: 1429px) {
    .title{
        font-size: 35pt!important;
    }

    .section-1-wrap-extra{
        max-width: 50% !important;
    }

    .section-1-wrap, .section-1-wrap-any{
        max-width: 50% !important;
    }

    .details{
        font-size: 20px!important;
        line-height: 30px;
    }

    section.two{
        margin-top: 180px!important;
    }
}

@media only screen and (max-width: 1335px) {
    .section-1-wrap-extra{
        max-width: 45% !important;
    }

    .section-1-wrap, .section-1-wrap-any{
        max-width: 45% !important;
    }

    section.two{
        padding: 0!important;
        margin-top: 190px!important;
    }

    section.two-{
        margin-top: 300px!important;
    }

    .sub-headings{
        column-gap: 200px!important;
    }

}

@media only screen and (max-width: 1230px) {
    .section-1-wrap-extra{
        max-width: 40% !important;
    }

    .title, .why {
        font-size: 32pt !important;
    }

    .details{
        font-size: 18px!important;
    }

    section.two{
        margin-top: 150px!important;
    }

    section.two-{
        margin-top: 250px!important;
    }

    .section-1-wrap, .section-1-wrap-any{
        max-width: 40% !important;
    }
}

@media only screen and (max-width: 1152px) {
    .section-1-wrap-extra{
        max-width: 36% !important;
    }

    .section-1-wrap, .section-1-wrap-any{
        max-width: 36% !important;
    }

    .view-job-wrap{
        max-width: 80% !important;
    }

    .sub-headings{
        column-gap: 200px!important;
    }

    section.two-{
        padding: 0!important;
    }

}

@media only screen and (max-width: 952px) {

    .go-back {
        margin-top: 50px!important;
    }
}

@media only screen and (max-width: 900px) {

    .view-job-wrap{
        max-width: 80% !important;
    }

    .col-checks- img{
        margin-right: 0!important;
    }

    .sub-headings{
        column-gap: 100px!important;
    }

        section.one {
            margin: 0!important;
            padding: 0!important;
        }

        .title, .why {
            font-size: 32pt !important;
        }

        .section-1-wrap {
            max-width: 100% !important;
            margin: 800px 40px 0 40px!important;
            padding: 0!important;

        }

        .section-1-wrap-any {
            max-width: 100% !important;
            margin: 40px 30px 20px 30px!important;
            padding: 0;
        }

        #left-cirle, #right-circle {
            max-width: 30% !important;
            height: auto;
        }

        #right-circle {
            top: 300px;
        }

        .details {
            font-size: 20px !important;
        }

        section.two {
            padding: 0!important;
            margin: 15px 0 0 0!important;
        }

        section.two-{
            margin-top: 15px!important;
            padding-inline: 0!important;
            align-items: flex-start!important;
            margin-inline: 40px!important;
        }

        .stats {
            font-size: 15px !important;
        }

        section.three {
            margin-top: 10px !important;
            display: flex!important;
            flex-direction: column-reverse!important;
        }

        #second-left {
            width: 100% !important;
            position:unset!important;
        }

        .section-3-wrap {
            text-align: center !important;
            /* margin-right: 30px!important; */
            margin-left: 30px!important;

            position:unset!important;
        }

        .top-details {
            margin-bottom: 50px !important;
        }

        .sub-details {
            margin: 0 !important;
            column-gap: 10px!important;
        }

        .left-sub {
            margin: 0 0 0 0!important;
        }

        .items-wrapper {
            display: flex;
            flex-direction: column;
        }

        .txt-bg {
            font-size: 90px !important;
        }

        .bg-t {
            flex-direction: row !important;
            justify-content: center !important;
        }

        .info {
            font-size: 18px;
            line-height: 20px;
        }

        section.four {
            margin-top: 10px !important;
            margin-bottom: 50px !important;
            flex-direction: column-reverse!important;
        }

        #second-right {
            width: 100% !important;
        }

        .section-4-wrap {
            max-width: 100% !important;
            margin: 0 40px 0 40px !important;
        }


        .main-groupings-4 {
            max-width: 100% !important;
            margin: 20px 0 0 0 !important;
        }

        .sub-groupings-4 {
            margin: 0 0 50px 0 !important;
        }

        section.five {
            padding: 50px 25px !important;
            margin-top: 0!important;
        }

        .sub-groupings-5 {
            flex-direction: column !important;
        }

        .logo-circle {
            position: absolute;
            right: 40px;
            top: 40px;
            max-width: 90px;
        }

        .sub-groupings-5 {
            margin-top: 100px;
            text-align: center;
        }

        /*    about */
        .section-1-wrap-extra {
            max-width: 100% !important;
            margin: 800px 40px 0 40px!important;
        }

        section.three-extra {
            margin-top: 0 !important;
        }


        #first-left, #first-right-extra, #first-right {
            width: 35px!important;
            height: auto!important;
        }

        .section-3-wrap-extra {
            gap: 50px !important;
            align-self: center !important;
        }

        .digits {
            line-height: initial !important;
            margin-right: 10px !important;
            font-size: 50px !important;
        }

        .description {
            line-height: initial !important;
            width: 80% !important;
        }

        .col-info {
            margin-bottom: 50px !important;
        }

        section.four-extra {
            margin: 0 30px !important;
            max-width: 100% !important;
            padding: 0 !important;
            align-items: flex-start!important;
        }

        section.five-extra{
            margin: 30px 30px 5px 30px!important;
            max-width: 100% !important;
        }

        .items{
            margin: 0 15px 0 15px;
        }

        .items span{
            font-size: 11px;
    
        }

        .rols{
            align-items: center!important;
        }

        section.six{
            gap: 10px!important;
            margin: 0 40px 40px 40px!important;
            max-width: 100% !important;
            font-size: 8pt!important;
            justify-items: center!important;
        }

        .fa-angle-right{
            border-radius: 50% !important;
            width: 25px!important;
            font-size: 15pt!important;
        }

        /*    finance */

        .section-2-wrap-x{
            flex-direction: column!important;
            text-align: left!important;
            margin-top: 0!important;
            align-content: center!important;
        }

        .section-2-wrap-x-{
            flex-direction: column-reverse!important;
            max-width: 100% !important;
            margin: 0 40px 0 40px!important;
            text-align: left!important;
            row-gap: 20px!important;
        }

    .section-2-wrap{
        margin-inline: 30px!important;
    }

        .col-description-- .details-fade,
        .col-description-- .sub-title,
        .col-description-- .details-fade{
            text-align: center!important;
        }

        .col-description--{
            margin-left: 0!important;
        }

        .map-iframe {
            margin: 0 0 40px 0!important;
        }


        .col-description, .section-2-wrap-x .col-description{
            max-width: 100%!important;
            margin: 0!important;
        }

        .col-description .video-img{
            height: 250px!important;
            margin-bottom: 45px!important;
        }

        /*.col-checks span{*/
        /*    margin-bottom: 20px!important;*/
        /*}*/

        .download {
            display:flex;
            flex-direction: column;
            row-gap: 10px;
            align-items: center;
        }

        .col-checks span.download select{
            margin-left: 0!important;
        }

        .two-extra .details img{
            max-width: 80px!important;
        }

        /*    business*/
        .col-checks img {
            margin:0 !important;
        }

        .two-extra span.extra-details img{
            max-width: 550px!important;
            height: auto!important;
        }
        .extra-details{
        font-size: 20px!important;
        line-height: 30px!important;
        margin-bottom: 10px!important;
        }
        .other-details{
            font-size: 25px!important;
            margin: 0 0 40px 0!important;
        }

        .btns{
            margin:  0 0 60px 0!important;
        }

        /*    careers*/
        section.other-section{
            margin: 20px 0 0 0!important;
            padding-inline: 35px!important;
        }

        .other-section-wrap{
            max-width: 100% !important;
        }

        .section-5-wrap{
            margin-top: 80px!important;
        }

        .other-section-wrap .section-5-wrap .title{
            font-size: 30px!important;
        }

        .job-card{
            background: #ffffff!important;
            padding: 5px!important;
            max-width: 100% !important;
            justify-content: space-between!important;
        }

        .job-card .btn{
            font-size: 12px!important;
        }

        .image-display{
            grid-template-columns: auto auto!important;
            column-gap: 30px!important;
        }


        .disclaimer{
            max-width: 100% !important;
            padding: 20px!important;
        }

        section.two-addition{
            max-width: 100% !important;
            padding: 0!important;
            margin-top: 10px!important;
            row-gap: 40px!important;
        }


        .one-extra{
            padding: 0!important;
            margin: 50px 40px 0 40px!important;
        }

        .card-contact{
            grid-template-columns: auto!important;
            grid-row-gap: 50px!important;
        }

        .contact-card-wrap{
            border-radius: 10px;
            max-width: 100% !important;
        }

        .contact-form{
            max-width: 100% !important;
        }

    .form-for-contact{
        display: flex;
        margin: 40px 0 40px 0!important;
        justify-content: center!important;
    }
}

@media only screen and (max-width: 850px) {

    .sides-wrap {
        flex-direction: column !important;

    }

    .sides-wrap img {
        max-width: 100% !important;
        height: 250px !important;
        border-top-left-radius: 20px !important;
        border-top-right-radius: 20px !important;
        border-bottom-left-radius: 0 !important;
    }

    .sides-wrap .news-side {
        border-top-style: none !important;
        border-top-right-radius: 0 !important;
        border-left-style: solid !important;
        border-right-style: solid !important;
        border-bottom: solid !important;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
        padding: 15px!important;
    }

    .scroll-text .mar, .scroll-text .header{
        padding: 10px!important;
        font-size: 12pt!important;
    }
}