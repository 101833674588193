@import "./components/contact/_Contact.css";
@import "./components/_AlertDialogSlide.css";
@import "./components/media/LargeDevices.css";
@import "./components/media/SmallDevices.css";

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
}


body{
    font-family: Roboto;
}

nav{
    height: 80px;
    width: 100%;
    background: white;
    opacity: 0.9;
    position: sticky;
    z-index: 100;
    top: 0;

}

nav label.logo{
    padding-left: 40px;
    /* margin-top: 80px; */
}

nav label.logo img{
    max-width: 230px;
    margin-top: 15px;
    height: auto;
}

nav ul {
    float: right;
    margin-right: 20px;
}

nav ul li{
    display: inline-block;
    line-height: 80px;
    margin: 0 15px;
}

nav ul li a{
    text-decoration: none;
    color: #0F4C8A;
}


nav ul li a:hover{
    text-decoration: none;
    color: #FF7A00;
}

.checkbtn{
    font-size: 32px;
    line-height: 80px;
    color: #FF7A00;
    float: right;
    margin-right: 40px;
    cursor: pointer;
    display: none;
}

#check{
    display: none;
}

.playstore{
    float: right;
    margin: 25px 20px 0 0;
    max-width: 100px;
    height: auto;
}

@media (max-width: 952px){
    label.logo img{
        max-width: 200px!important;
    }

    nav label.logo{
        padding-left: 5px;
    }

    nav ul li a{
        font-size: 16px;
    }

    .playstore{ 
        position: fixed;
        max-width: 100px;
        top: 85px;
        left: 18px;
        margin: 0;
     }
}

@media (max-width: 858px){

    .checkbtn{
        display: block;
    }

    nav{
        opacity: 1;
        overflow-y: scroll;
    }

    nav ul{
        position: fixed;
        width: 50%;
        height: 100vh;
        top: 80px;
        left: -100%;
        text-align: center;
        background-color: rgb(240, 240, 240);
        /* background-image: url("./assets/nav-bg.png");
        background-repeat: no-repeat;
        background-size: contain; */
        transition: all .5s;

    }
    nav ul li {
        display: block;
        line-height: 50px!important;
        margin: 0!important;
    }
    
    nav ul li a{
        font-size: 20px;
    }

    a:hover{
        background: none;
        color: black;
    }

    #check:checked ~ ul{
    left: 0;
    }
}

/* footer */
.group-footer{
    position: relative;
    display: flex;
    flex-direction: row;
    border-top: solid #FF7A00 3px ;
    justify-content: space-between;
    padding-top: 40px;
    margin-inline: 50px;
    margin-block: 50px;
}

.contact-info{
    font-size: 20pt;
    line-height: 40px;
}

.contact-info a{
    color: #FF7A00;
}

.contact-us-sub{
    text-indent: 8px;
    font-size: 22px;
    line-height: 35px;
}

.socials{
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    column-gap: 5px;
    font-size: 30pt;
}

.contact-us-sub a{
    color: #0F4C8A;
}

.socials a{
    color: #0F4C8A;
    text-decoration: none;
    text-align: center;
}

.group-footer ul{
    list-style: none;
    color: #0F4C8A;
}

.group-footer a{
    text-decoration: none;
    /* color:  #FF7A00; */
}

.group-footer a:hover{
    color: #d46700;
    text-decoration: none;
}

.footer-logo {
    width: 200px!important;
}

/* footer ends */

/* general */
/*@keyframes change-img-anim {*/
/*    0% {opacity: 0;}*/
/*    20% {opacity: 1;}*/
/*    33% {opacity: 1;}*/
/*    53% {opacity: 0;}*/
/*    100% {opacity: 0;}*/
/*}*/

/*@-o-keyframes change-img-anim {*/
/*    0% {opacity: 0;}*/
/*    20% {opacity: 1;}*/
/*    33% {opacity: 1;}*/
/*    53% {opacity: 0;}*/
/*    100% {opacity: 0;}*/
/*}*/

/*@-moz-keyframes change-img-anim {*/
/*    0% {opacity: 0;}*/
/*    20% {opacity: 1;}*/
/*    33% {opacity: 1;}*/
/*    53% {opacity: 0;}*/
/*    100% {opacity: 0;}*/
/*}*/

/*@-webkit-keyframes change-img-anim {*/
/*    0% {opacity: 0;}*/
/*    20% {opacity: 1;}*/
/*    33% {opacity: 1;}*/
/*    53% {opacity: 0;}*/
/*    100% {opacity: 0;}*/
/*}*/
/*.image-cont .imageAni{*/
/*    -webkit-animation: change-img-anim 10s infinite;*/
/*    -moz-animation: change-img-anim 10s infinite;*/
/*    -o-animation: change-img-anim 10s infinite;*/
/*    animation: change-img-anim 10s infinite;*/
/*}*/

/*.image-cont:nth-child(1) {*/
/*    animation-delay: 0.5s !important;*/
/*}*/
/*.image-cont:nth-child(2) {*/
/*    animation-delay: 1s !important;*/
/*}*/
/*.image-cont:nth-child(3) {*/
/*    animation-delay: 1.5s!important;*/
/*}*/
/*.image-cont:nth-child(4) {*/
/*    animation-delay: 2s!important;*/
/*}*/
/*.image-cont:nth-child(5) {*/
/*    animation-delay: 2.5s!important;*/
/*}*/

.section-1-wrap-extra{
    max-width: 60%;
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    align-items: flex-start;
}

section.three-extra{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    z-index: -1;
}

section.four-extra{
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 50px 0 50px;
    align-items: center;
    margin-top: 30px;
    z-index: -1;
}

#first-left{
    position: absolute;
    left: 0;
    z-index: -1;
    max-width: 100% !important;
    height: auto!important;
}

#first-right{
    position: absolute;
    right: 0;
    margin-top: 900px;
    z-index: -1;
    max-width: 100% !important;
    height: auto!important;
}

#first-right-extra{
    position: absolute;
    right: 0;
    margin-top: 500px;
    z-index: -1;
}

.section-3-wrap-extra{
    display: flex;
    flex-direction: row;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    gap: 150px;
}

.details{
    margin:  0 0 30px 0;
    color: #0F172A;
    font-size: 22px;
    line-height: 30px;
    text-align: justify;
    word-spacing: -0.5px;
}


#screen {
    max-width: 100% !important;
    height: auto!important;
}

.extra-details{
    margin-bottom: 30px;
    color: #224eb4;
    font-size: 22px;
    line-height: 30px;
}

.other-details{
    font-size: 35px;
}

.details-fade{
    font-size: 22px;
    color: #919191;
}

.title{
    font-size: 50pt;
    margin:  0 0 20px 0;
    font-weight: bold;
}

.sub-title{
    font-size: 30pt;
    margin:  0 0 20px 0;
    font-weight:bold;
}

.btns{
    margin:  0 0 100px 0;
}

.btns a:hover{
    text-decoration: none;
}

a.get-started:hover{
    background-color: #d46904;
    color: white;
}

a.know-more:hover{
    background-color:#0F4C8A;
    color: white;
}

.get-started, .know-more{
    padding: 10px 20px 10px 20px;
    border-radius: 40px;
    margin-right: 20px;
}

a.get-started{
    background: #FF7A00;
    color: white;
}

a.get-started .fa-arrow-right{
    padding:  0 0 0 10px;
}

a.know-more{
    color: #0F4C8A;
    border-style: solid;
    border-width: 1px;
}

#top-right{
    position:absolute;
    right: 0;
    top: 0;
    z-index: -1;
    max-width: 100%;
    height: auto!important;
}


/* general ends */


/* section one */
section.one{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    z-index: 1;
    padding: 50px 50px 0 50px;
}

section.one-extra{
    position: relative;
    z-index: 0;
    max-width: 100%;
    padding: 50px 100px 0 100px;
}

.section-1-wrap, .section-1-wrap-any{
    max-width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


.fs{
    color: #0F4C8A;
    margin:  10px 0 30px 0;
    font-size: 25px;
    font-weight:bold;
}
/* section one ends */

/* section two */
section.two{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 100px 50px 0 50px;
    margin-top: 100px;
}

section.two-{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-inline: 50px;
    margin-top: 350px;
}

section.two-addition{
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 100% !important;
    margin-top: 100px;
    row-gap: 100px;
}

section.two-extra{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: -1;
    padding: 0 100px 0 100px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.stats{
    color: #FF7A00;
    font-size: 36px;
}

#left-cirle{
    position: absolute;
    left: 0;
    z-index: -1;
    top: 210px;
    /*max-width: 100%;*/
    /*height: auto;*/
}

#right-circle{
    position: absolute;
    right: 0;
    z-index: -1;
    bottom: 0;
    max-width: 100%;
    height: auto;
}


.section-2-wrap{
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

/* section three */
    section.three{
        margin-top: 450px;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        z-index: -1;
        max-width: 100%;
    }

.section-3-wrap{
    display: flex;
    flex-direction: column;
    max-width: 70%;
    position: absolute;
    right: 0;
    margin-inline: 50px;
}

.top-details{
    display: flex;
    flex-direction: column;
    line-height: 30px;
    margin-bottom: 80px;
}

.top-details .service{
    color: #FF7A00;
    text-transform: uppercase;
    font-size: 20px;
}

.why{
    font-size: 50px;
    font-weight: bold;
    margin: 10px 0 30px 0;
}

.why-info{
    font-size: 22px;
}

.sub-details{
    display: flex;
    flex-direction: row;
    margin: 0;
    column-gap: 20px;
}

.left-sub{
    display: flex;
    flex-direction: column;
}

.right-sub{
    display: flex;
    flex-direction: column;
}

.items-wrapper{
    display: flex;
    margin: 0 0 50px 0;
}

.bg-t{
    display: flex;
    margin: 0 0 0 10px;
    flex-direction: column;
    justify-content: center;
}

.txt-bg{
    font-size: 100px;
    color: #ABB8C3;
    opacity: 0.3;
    font-family: Sansation;
    font-weight: bold;
    position: absolute;
    z-index: -1;
}

#second-left{
    position:absolute;
    left: 0;
    z-index: -1;
    max-width: 100% !important;
    height: auto!important;
}
/* section three ends */


/* section four */
section.four{
    margin-top: 350px;
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    justify-content: center;
    align-items: center!important;
    z-index: -1;
}

.section-4-wrap{
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin-left: 50px;
}

.main-groupings-4{
    display: flex;
    flex-direction: row;    
    justify-content: space-between;
    max-width: 100%;
    margin: 50px 0 0 0;
    column-gap: 50px;
}

.sub-main-groupings-4{
    display: flex;
    flex-direction: column;    
}

.sub-groupings-4 {
    margin: 0 0 50px 0;
    display: flex;
    flex-direction: column;
}

.title-4{
    color: #0F4C8A;
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 10px 0;
}

#second-right{
    /*position:absolute;*/
    /*right: 0;*/
    z-index: -1;
    max-width: 100% !important;
    height: auto!important;
}
/* section four ends */

/* section five */
section.five{
    position: relative;
    z-index: 1;
    margin-top: 50px;
    left: 0;
    background: #0F4C8A;
    padding: 20px 50px;
    height: initial;
    display: flex;
    flex-direction: column;
    color: white;
   }

.sub-groupings-5{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   }

.groupings-5 {
    display: flex;
    flex-direction: column;
}

.groupings-5 .details{
    color: white;
}

.logo-circle-5{
    line-height: 120px;
}

.logo-circle{
    max-width: 100px;
}

.card-detail-wrapper{
    width: 90%;
    height: 45%;
    margin: 20px;
    background: rgb(255, 255, 255);
    word-wrap: break-word;
    border-radius: 10px;
    padding: 0 25px 10px;
    position: relative;
    bottom: -48%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #0F172A;
}

.p-pic{
    width: 50px;
    border-style:solid;
    border-color: white;
    border-width: 5px;
    border-radius: 100%;
    position: relative;
    top:-30px;
    margin-bottom: 0;
} 

.c-info {
    margin-top: 10px;
    position: relative;
    top:-30px;
    display: flex;
    flex-direction: column;
}

.c-name {
    font-weight: bold;
    font-size: 15px;
}

.c-position {
    font-size: 11px;
    color: #6f7070;
}

.c-details {
    margin-top: 10px;
    font-size: 13px;
    font-weight: 200;
}

.c-info + img {
    position: relative;
    top: -15px;
}

.slick-s {
    margin-top: 35px;
}

.info{
    line-height: 25px;
}

.first-col{
    margin-top: 100px;
}

.col-info{
    display: flex;
    margin-bottom: 80px;
}
.digits{
    line-height: 60px;
    margin-right: 10px;
    font-size: 70px;
    color: #004FFB;
}

.description{
display: flex;
flex-direction: column;
line-height: 40px;
font-weight: bold;
width: 80%;
}

.middle-banner{
    position: relative;
    left: 0;
    width: 100%;
    z-index: -2;;
}

section.five-extra{
    margin: 30px auto 5px auto;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    z-index: -1;
}

.image-about{
    margin-bottom: 70px;
}

.image-about-x{
    margin-bottom: 10px;
}

.rols{
    display: flex;
}

.items{
    display: flex;
    flex-direction: column;
    align-items: center;   
    text-align: center;
    margin: 0 50px 0 50px;
}

.items img{
    max-width: 100%;
    height: auto;
}

section.six{
    position: relative;
    display: flex;
    gap: 50px;
    margin: 0 50px 0 50px;
    border-top-style: solid;
    border-top-width: 2px;
    max-width: 100%;
    justify-content: space-evenly;
    justify-items: center;
    
    font-size: 30pt;
}

.vl {
    border-left: 2px solid;
  }

section.six .left, .right{
    padding: 20px;
    display: flex;
    gap: 50px;
}


section.six i{
    color: black;
}

.section-2-wrap-x{
    margin: 50px auto 40px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 80%;
}

.section-2-wrap-x-{
    margin: 0 100px 5px 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 100% !important;
    column-gap: 30px;
}

#first-right-finance{
    position:absolute;
    right: 0;
    z-index: -1;
    top: 1500px;
}

.col-checks .fa-check{
    background: #DEC9E7;
    padding: 10px;
    border-radius: 12px;
    color: #8E44AA;
    margin-right: 10px;
    max-height: 40px;
}

.col-description{
    max-width: 60%;
    margin-right: 50px;
    display: flex;
    flex-direction: column;
}

.col-description-, .col-description--{
    display: flex;
    row-gap: 10px;
    flex-direction: column;
    margin-left: 5px;
}

.col-checks{
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.col-checks span{
    /*margin-bottom: 50px;*/
}

.col-checks span.download select{
    width: 200px;
    height: 40px;
    text-indent: 10px;
    margin-left: 15px;
    border-style: solid;
    border-color: #FF7A00;
    border-width: 2px;
    outline: none;
    border-radius: 5px;
    color: white;
    background: #FF7A00;
}

.col-checks span.download select option{
    color:  #FF7A00;
    background: white;
}

.download {
    font-weight: bold;
    font-size: 18px;
    margin-top: 20px;
}

.download a{
    margin-left: 15px;
    padding-inline: 25px;
    padding-block: 8px;
    border-radius: 5px;
    color: white;
    background: #FF7A00;
}

.download a:active, .download a:hover{
    padding-inline: 28px;
    padding-block: 10px;
}

.col-description .video-img{
    height: 400px;
    border-radius: 10px;
    justify-content: center;
    box-shadow: 0 0 20px 0 rgb(207, 207, 207);
    
}

.gh-map{
    max-width: 100%!important;
    height: auto!important;
}

.col-description i.fa-check{
    background: #DAF8FA;
    padding: 10px;
    border-radius: 12px;
    color: #00CCDD;
    margin-right: 10px;
}

.checks-wrapper-{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.checks-wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}

section.other-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
    margin-top: 50px;
    padding-inline: 50px;
}

.other-section-wrap{
    max-width: 100%;
}

.all{
    border-style: solid;
    border-width: 2px;
    padding: 10px;
    border-radius: 20px;
    margin-right: 5px;
    margin-bottom: 5px;
    max-width: max-content;
    float: left;
    cursor: pointer;
}

.all:hover{
    border-color: #FF7A00;
    color: #FF7A00;
}

.jobOpenings{
    display: flex;
    flex-direction: column;
}

.job-cards-wrap{
    display: flex;
    flex-direction: row;
    /* column-gap: 20px;*/
    /* padding: 20px;  */
    margin-top: 25px;
    overflow-x: scroll;
    border-radius: 20px;
    /* background: #f1f1f1; */
}

.job-cards-inner-wrap{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    padding: 15px;
    /* overflow-x: scroll;*/
    border-radius: 20px; 
    background: #f1f1f1;
}

.job-cards-wrap::-webkit-scrollbar{
    display: none;
    scroll-behavior: smooth;
}

.fi-det{
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 20px;
    padding: 20px;
    margin-top: 25px;
    overflow-x: scroll;
    background: #f1f1f1;
    max-width: 100%;
}

.fi-det::-webkit-scrollbar{
      display: none;
      scroll-behavior: smooth;
  }

.job-card{
    background: #f8f8f8;
    display: flex;
    flex-direction: column;
    padding: 10px;
    max-width: 300px;
    border-radius: 15px;
    row-gap: 40px;
}

.job-inner-card{
    display: flex;
    flex-direction: column;
    padding: 10px;
    row-gap: 20px;
    width: 100%;
    word-wrap: break-word;
    border-radius: 15px;
}

.job-card .btn{
    background: #0F4C8A;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    color: white;
}

.job-card .date-desc{
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}

.date-desc-extra{
    display: flex;
    flex-direction: column;
}
.posted-time{
    font-size: 11px;
    color: #8f8f8f;
}
.section-5-wrap{
    display: flex;
    flex-direction: column;
    align-items:  center;
    margin-top: 80px;
}

.disclaimer{
    max-width: 100%;
    background: #F9F5F5;
    padding: 40px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.image-display{
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: auto auto auto;
    row-gap: 50px;
    column-gap: 50px;
    z-index: -1;
}

.image-display .item{
    max-width: 100% !important;
    height: auto!important;
}

.bus-check{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 19px;
}



/*map*/
.map-iframe {
    width: 100%;
    height: 400px;
    border-radius: 12px;
    border: 0;
}

.fa-phone, .fa-envelope{
    color: #0F4C8A;
    margin-right: 10px;
    font-size: 15px;
}

.col-description .cont{
    margin-top: 15px;
    font-size: 25px;
}

.col-checks img{
    border-radius: 30px;
    margin-right: 50px;
    max-width: 100%!important;
    height: auto!important;
}

.col-checks- img{
    border-radius: 30px;
    margin-right: 50px;
    max-width: 100%!important;
    height: auto!important;
}

