.swiper{
    width: 100%;
    height: 100%;
}

.swiper-slider{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}