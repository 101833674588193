.fa-times{
    color: black;
    padding: 10px;
    margin: 0;
}


.img-details-wrap{
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    column-gap: 10px;
    align-items: center;
    margin-top: 25px;
    bottom: 10px;
    left: 30px;
}

.img-details{
    display: grid;
    grid-template-rows: auto auto;
    justify-content: start;
    row-gap: 5px;
}

.img-details span{
    font-size: 14px;
    color: #5d5d5d;
}

.dialog {
    background: transparent;
    padding: 15px 15px 0 0;
    font-size: 13pt;
}

.btn-launch{
   font-size: 10px;
    color: #ffffff;
    cursor:pointer;
    padding-inline: 10px ;
    padding-block: 3px ;
    border-radius: 5px;
    background: #1c64ad;
}

.p-picture{
    width: 70px;
    border-style:solid;
    border-color: white;
    border-width: 5px;
    border-radius: 100%;
}