.pt-container {
    max-width: 70vw;
    margin: 5vh auto 50px;
}

#pt-header {
    width: 70vw;
    background-color: white;    
}

#pt-heading {
    padding-bottom: 7px;
}

#pt-heading span {
    font-size: 20px;
    color: #373737;
    font-weight: bold;
}

.pt-content {
    display: block;
    text-align: justify;
}

.pt-service {
    margin:2px 0 10px 12px;
}

.pt-container ol {
    padding-right: 30px;
    list-style-type: decimal;
    margin: 1em 0;
    padding: 0 40px;
}

.pt-container li {
    padding-top: 15px;
}

ol  p, div p {
    margin: 12px 0 ;
}

.pt-container ol p span {
    display: block;
    margin: 7px 12px ;
}

#annexures {
    margin-top: 20px;
}

#annexures h3 {
    margin-top: 2em;
}

.tableDiv {
    position: relative;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 2px solid #ccc;
    padding: 0 10px 10px;
}

tbody td:nth-child(1) {
    text-align: center;;
}

tbody td:nth-child(3){
    min-width: 350px;
}

td ul {
    padding: 0 20px;
}


.tableDiv::-webkit-scrollbar{
    scroll-behavior: smooth;
} 
 
@media screen  and (max-width: 1280px){
    .pt-container {
        min-width: 80vw;
    }

    #pt-header {
        width: 80vw;
    }
}

@media screen and (min-device-width: 481px) and (max-device-width: 767px) {                 
    .pt-container {
        min-width: 500px;
    }

    #pt-header {
        min-width: 500px;
    }
}

@media only screen and (max-width: 480px) {
    .pt-container {
        max-width: 100%;
    }
    
    #pt-header {
        min-width: 100%;
        flex-direction: column;
        align-items: center;
    }   

    .pt-content-bg {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: url('https://d1gruoz2d8pn1p.cloudfront.net/logo-idle.png');
        background-size: 300px;
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        filter: blur(5px);
        opacity: 0.1;
    }   

    #pt-heading {
        text-align: center;
    }


    .pt-content {
        width: 100%;
        padding: 0 20px ;
    }

    .pt-container ol {
        padding: 5px ;
    }

    .pt-container .tableDiv {
        width: 100%;
        overflow: auto;
    }  

    #annexB tbody td:nth-child(2), 
    #annexC tbody td:nth-child(2){
        min-width: 300px;
    }

    
} 