.view-job-wrap{
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    margin-inline: auto;
    margin-bottom: 30px;
    max-width: 50%;
    transition: display 10s;
}

.top-banner{
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    padding-inline: 40px;
    padding-block: 50px;
    max-width: 100%;
    background: #eeeeee;
    border-radius: 10px;
}

.apply{
    padding-inline: 30px;
    padding-block: 10px;
    max-width: 100px;
    border-radius: 20PX;
    border-style: none;
    background: #0F4C8A;
    color: white;
}

.apply i{
    margin-left: 5px;
}

.apply:hover{
    background: #1c64ad;
}

.apply:active{
    background: #0F4C8A;
}

.sub-headings{
    display: flex;
    flex-direction: row;
    column-gap: 500px;
    color: #0F4C8A;
}

.sides-title, .view-title{
    color: #0F4C8A;
}

.view-details{
    display: flex;
    flex-direction:column;
    row-gap: 50px;
}

.view-details ul{
    text-align: left ;
    word-spacing: -0.5px;
}

.view-details ul li{
    margin: 10px 0 0 25px;
    line-height: 25px;
    color: #4d4d4d;
    list-style-type: disc;
}

.sides-details{
    color: #4d4d4d;
}

.for-news{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.sides-wrap{
    display: flex;
    flex-direction: row;
    background: #e1e6ed;
    border-radius: 20px;
}

.sides-wrap img{
    max-width: 250px;
    object-fit:cover;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.sides-wrap .news-side{
    border-top-style: solid;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-right-style: solid;
    border-width: 1px;
    border-bottom: solid;
    border-color: #b5b5b5;
    padding: 25px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.news-side a{
    text-decoration: underline;
}

.scroll-text{
    display: flex;
    flex-direction: row;
    max-width: 100%;
    align-items: center;
    color: white;
    background: orangered;
}

.scroll-text .header{
    background: #0F4C8A;
    padding: 20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.scroll-text .mar{
    padding: 20px;
    background: orangered;
}

.scroll-text .mar h3 {
    display: inline-block;
    animation: marquee 8s linear infinite;
}
@keyframes marquee {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(285%, 0); }
}

.responsibilities p{
    line-height: 25px;
    color: #4d4d4d;
}
