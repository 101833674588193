
@media only screen and (max-width: 600px) {

    .view-job-wrap{
        max-width: 100% !important;
        padding-inline: 20px;
    }

    #top-right{
        top: 50px!important;
    }

    .checks-wrapper{
        gap: 30px!important;
    }

    .form-for-contact{
        display: flex;
        margin: 0 0 0 0!important;
        justify-content: center!important;
    }

    .job-card .date-desc{
        flex-direction: row!important;
        justify-content: space-between!important;
    }

    .top-banner{
        padding-inline: 20px!important;
        padding-block: 20px!important;
    }

    .sub-headings{
        column-gap: 100px!important;
    }

    section.one {
        margin: 0!important;
        padding: 0!important;
    }

    .title, .why {
        font-size: 28pt !important;
    }

    .five-extra .title {
        font-size: 20pt !important;
    }

    .five-extra h2 {
        font-size: 15pt !important;
    }

    .section-1-wrap {
        max-width: 100% !important;
        margin: 650px 15px 0 15px!important;
        padding: 0!important;
    }

    .section-1-wrap-any {
        max-width: 100% !important;
        margin: 20px 15px 0 15px!important;
        padding: 0;
    }

    #left-cirle, #right-circle {
        max-width: 30% !important;
        height: auto;
    }

    #right-circle {
        top: 300px;
    }

    .fs {
        font-size: 20px !important;
    }

    .details, .extra-details {
        font-size: 14px !important;
    }

    .five .details {
        text-align: center!important;
    }

    .details{
        text-align: start!important;
    }
    .extra-details {
        line-height: 22px !important;
    }

    section.two{
        padding: 0!important;
        margin: 15px 0 0 0!important;
    }

    section.two- {
        padding-inline: 0!important;
        align-items: flex-start!important;
        margin-inline: 15px!important;
        margin-top: 5px!important;
    }

    .section-2-wrap {
        margin-inline: 15px!important;
    }

    .stats {
        font-size: 15px !important;
    }

    section.three {
        margin-top: 10px !important;
        display: flex!important;
        flex-direction: column-reverse!important;
    }

    #second-left {
        max-width: 550px !important;
        position:unset!important;
    }

    .section-3-wrap {
        max-width: 100% !important;
        margin-inline: 15px !important;
        text-align: center !important;
        position:unset!important;
    }

    .top-details {
        margin-bottom: 50px !important;
    }

    .sub-details {
        margin: 0 !important;
        column-gap: 10px!important;
    }

    .left-sub {
        margin: 0 0 0 0!important;
    }

    .items-wrapper {
        display: flex;
        flex-direction: column;
    }

    .txt-bg {
        font-size: 110px !important;
    }

    .bg-t {
        flex-direction: row !important;
        justify-content: center !important;
    }

    .info {
        font-size: 13px;
        line-height: 20px;
    }

    section.four {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        flex-direction: column-reverse!important;
    }

    #second-right {
        max-width: 550px !important;
        position: unset!important;
    }

    .section-4-wrap {
        max-width: 100% !important;
        margin: 0 15px 0 15px !important;
    }

    .main-groupings-4 {
        max-width: 100% !important;
        margin: 10px 0 0 0 !important;
    }

    .sub-main-groupings-4{
        row-gap: 40px!important;
    }

    .sub-groupings-4 {
        margin: 0 0 0 0 !important;
    }

    section.five {
        padding: 30px 15px !important;
    }

    .logo-circle {
        position: absolute;
        right: 40px;
        top: 15px;
        max-width: 90px!important;
    }

    .sub-groupings-5 {
        flex-direction: column !important;
        margin-top: 90px!important;
        text-align: center;
    }

    /*    about */
    .section-1-wrap-extra {
        max-width: 100% !important;
        margin: 650px 15px 0 15px!important;
    }

    #first-left, #first-right-extra, #first-right {
        width: 20px!important;
        height: auto!important;
    }

    .section-3-wrap-extra {
        gap: 10px !important;
        max-width: 85% !important;
    }

    .first-col{
        margin-top: 20px!important;
    }

    .digits {
        line-height: initial !important;
        margin-right: 10px !important;
        font-size: 50px !important;
    }

    .description {
        line-height: initial !important;
        width: 60% !important;
    }

    .col-info {
        margin-bottom: 50px !important;
    }

    section.four-extra {
        margin: 0 15px !important;
        max-width: 100% !important;
        padding: 0!important;
    }

    section.five-extra{
        margin: 30px 15px 5px 15px!important;
        max-width: 100% !important;
    }

    .items{
        margin: 0 15px 0 15px;
    }

    .items span{
        font-size: 11px;
        text-align: center;
    }

    .rols{
        display: grid!important;
        grid-template-columns: auto!important;
        align-items: center!important;
    }

    section.six{
        gap: 10px!important;
        flex-direction: column!important;
        margin: 0 15px 40px 15px!important;
        max-width: 100% !important;
        border-top-style: none!important;
        border-top-width: 0!important;
        justify-items: center!important;
        font-size: 16pt!important;
    }

    .vl {
        border-left: none;
        border-top: 2px solid;
        max-width: 100% !important;
    }

    section.six .left, .right{
       justify-content: space-between;
    }

    section.six i.fa-angle-right{
        text-align: center!important;
        padding: 0!important;
    }

    .fa-angle-right{
        border-radius: 50% !important;
        width: 25px!important;
        font-size: 15pt!important;
    }

/*    finance */

    .section-2-wrap-x{
       flex-direction: column!important;
        text-align: left!important;
        margin-inline: 15px!important;
        align-content: center!important;
    }

    .section-2-wrap-x-{
        flex-direction: column-reverse!important;
        max-width: 100% !important;
        margin: 15px 15px 0 15px!important;
        text-align: left!important;
        row-gap: 0!important;
    }

    .col-description-- .details-fade,
    .col-description-- .sub-title,
    .col-description-- .details-fade{
        text-align: center!important;
    }

    .col-description-{
        margin-bottom: 30px!important;
    }

    .map-iframe {
        margin: 0 0 40px 0!important;
    }


    .col-description, .section-2-wrap-x .col-description{
        max-width: 100%!important;
        margin: 0!important;
    }

    .col-description .video-img{
        height: 250px!important;
        margin-bottom: 45px!important;
    }

    .download {
        display:flex;
        flex-direction: column;
        row-gap: 10px;
        align-items: center;
    }

    .col-checks span.download select{
        margin-left: 0!important;
    }

    section.two-extra{
        padding: 0 0 0 0!important;
        margin: 0 15px 0 15px !important;
    }

    section.two-extra .details{
        margin-bottom: 0!important;
    }

    .two-extra .section-2-wrap .details{
        margin:  0 0 30px 0!important;
    }

    .two-extra .details img{
        max-width: 80px!important;
    }

/*    business*/

    .col-checks img {
        margin: 50px 0 0 0 !important;
    }

    .two-extra span.extra-details img{
        max-width: 350px!important;
        height: auto!important;
    }

    .other-details{
        font-size: 25px!important;
        margin: 0 0 40px 0!important;
    }

    .btns{
        margin:  0 0 60px 0!important;
    }

/*    careers*/
    section.other-section{
        margin: 10px 0 0 0!important;
        padding: 0!important;
    }

    .other-section-wrap{
        max-width: 100% !important;
        margin-inline: 15px!important;
    }

    .section-5-wrap{
        margin-top: 40px!important;
    }

    .other-section-wrap .section-5-wrap .title{
        font-size: 25px!important;
    }

    .job-cards-wrap{
        flex-direction: column!important;
        row-gap: 10px!important;
        overflow-x: hidden!important;
        overflow-y: scroll!important;
        max-height: 500px!important;
        /* background: #e0e2e3!important; */
        /* padding: 10px!important; */
    }

    .job-cards-inner-wrap{
        flex-direction: column!important;
        row-gap: 10px!important;
        padding: 10px!important;
        /* overflow-x: scroll;*/
    }

    .job-card{
        background: #ffffff!important;
        flex-direction: row!important;
        padding: 5px!important;
        justify-content: space-between!important;
    }

    .image-display{
        grid-template-columns: auto auto!important;
        column-gap: 30px!important;
    }

    .disclaimer{
        max-width: 100% !important;
        padding: 20px!important;
    }

    section.two-addition{
        max-width: 100% !important;
        padding: 0!important;
        margin-top: 0!important;
        row-gap: 0!important;
    }

    .one-extra{
        padding: 0!important;
        margin: 50px 15px 0 15px!important;
    }

    .card-contact{
        grid-template-columns: auto!important;
        grid-row-gap: 50px!important;
    }

    .contact-card-wrap{
        border-radius: 10px;
        max-width: 100% !important;
    }

    .contact-form{
        max-width: 100% !important;
        margin: 40px 0 40px 0!important;
    }
}

@media only screen and (max-width: 490px) {
    .section-1-wrap {
        margin-top: 520px!important;
    }

    #second-right {
        top: 0 !important;
    }

    .description{
        font-size: 13px!important;
        max-width: 90% !important;
    }

    .digits {
        margin-right: 5px !important;
        font-size: 40px !important;
    }

    .section-1-wrap-extra {
        margin-top: 550px !important;
    }

    .main-groupings-4{
        column-gap: 20px!important;
    }

    section.five-extra{
        margin-inline: 15px!important;
    }

    .title, .why, .sub-title {
        font-size: 22pt !important;
    }

    /*section.two {*/
    /*    padding-inline: 0!important;*/
    /*    align-items: flex-start!important;*/
    /*    margin-top: 5px!important;*/
    /*}*/

    .col-checks img {
        margin-top: 20px !important;
    }

    .title-4{
        font-size: 20px!important;
    }

    .disclaimer .details {
        font-size: 12px !important;
        line-height: 20px !important;
    }

    .col-description- .details-fade{
        font-size: 20px!important;
    }

}

@media only screen and (max-width: 430px) {
    .section-1-wrap {
        margin-top: 450px!important;
    }

    .section-1-wrap-extra {
        margin-top: 510px !important;
    }

    .bus-check i.fa-check{
        font-size: 17px!important;
    }

    .title, .why, .sub-title {
        font-size: 20pt !important;
    }

}

@media only screen and (max-width: 428px) {
    .section-1-wrap {
        margin-top: 480px!important;
    }
    .two-extra span.extra-details img{
        max-width: 310px!important;
        height: auto!important;
    }

}

@media only screen and (max-width: 415px) {
    .section-1-wrap {
        margin-top: 460px!important;
    }

    .section-1-wrap-extra {
        margin-top: 505px !important;
    }

    .title, .why, .sub-title {
        font-size: 18pt !important;
    }

    .contact-card-wrap img{
        max-width: 50% !important;
    }

    .contact-card-wrap .card-details{
        font-size: 12px!important;
    }

    .contact-card-wrap .card-email{
        font-size: 14px!important;
    }

    .contact-card-wrap .card-title{
        font-size: 16px;
    }
}

@media only screen and (max-width: 394px) {
    .section-1-wrap {
        margin-top: 440px!important;
    }

    .section-1-wrap-extra {
        margin-top: 470px !important;
    }

    .title, .why, .sub-title {
        font-size: 18pt !important;
    }

    .contact-card-wrap img{
        max-width: 50% !important;
    }

    .contact-card-wrap .card-details{
        font-size: 12px!important;
    }

    .contact-card-wrap .card-email{
        font-size: 14px!important;
    }

    .contact-card-wrap .card-title{
        font-size: 16px;
    }

    .description{
        font-size: 12px!important;
    }

    .digits {
        margin-right: 5px !important;
        font-size: 35px !important;
    }
}

@media only screen and (max-width: 390px) {

    .section-1-wrap {
        margin-top: 440px!important;
    }

    .get-started, .know-more{
        font-size: 15px!important;
    }

    .title, .why, .sub-title {
        font-size: 15pt !important;
    }
}

@media only screen and (max-width: 376px) {

    .section-1-wrap-extra {
        margin-top: 450px !important;
    }

    .get-started, .know-more{
        font-size: 13px!important;
    }

    .title, .why, .sub-title {
        font-size: 15pt !important;
    }

    .description{
        font-size: 11px!important;
    }

    .digits {
        font-size: 30px !important;
    }

    .two-extra span.extra-details img{
        max-width: 285px!important;
    }

}

@media only screen and (max-width: 361px) {

    .section-1-wrap-extra {
        margin-top: 420px !important;
    }

    .two-extra span.extra-details img{
        max-width: 275px!important;
    }

    .all{
        font-size: 12px!important;
    }

    .job-card .job-title, .job-card .desc{
        font-size: 12px!important;
    }

    .job-card .posted-time{
        font-size: 10px!important;
    }

    .job-cards-wrap{
        row-gap: 10px!important;
        padding: 10px!important;
    }

    .job-card{
        padding: 0!important;
        border-radius: 15px!important;
    }
}

@media only screen and (max-width: 321px) {
    .section-1-wrap {
        margin-top: 370px !important;
    }

    .section-1-wrap-extra {
        margin-top: 370px !important;
    }

    .get-started, .know-more{
        font-size: 9px!important;
    }

    .fs{
        font-size: 18px!important;
    }

    .why-info, .other-details {
        font-size: 14pt !important;
    }

    .items-wrapper .txt-bg {
        font-size: 90px !important;
    }

    .items-wrapper .info {
        font-size: 11px;
        line-height: 16px;
    }

    .two-extra span.extra-details img{
        max-width: 230px!important;
    }

    .col-description- .details-fade, .col-description-- .details-fade{
        font-size: 18px!important;
    }
}